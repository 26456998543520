import AdpListSection from "../components/adplist-section/adplist-section"
import Hero from "../components/hero/hero"
import Layout from "../components/layout"
import Project from "../components/project/project"
import React from "react"
import Section from "../components/section/section"
import Seo from "../components/seo"
import VideoSection from "../components/video-section/video-section"
import csaaImg from "../images/projects/tournament.png"
import deputyComplianceImg from "../images/projects/new-york-fairworkweek.png"
import deputyGamificationImg from "../images/projects/gamification.png"
import deputyGrowthExperimentsImg from "../images/projects/onboarding-experiments.png"
import deputyTourImg from "../images/projects/product-tour-research.png"
import safetyCultureImg from "../images/projects/onboarding-safety-culture.png"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />

    <Hero />

    <Section id="projects" heading="My Projects" background={true} className="">
      <div className="row">
        <div className="col-12 col-md-4">
          <Project
            title="Onboarding &amp; 1st time user experience"
            subTitle="SafetyCulture"
            img={safetyCultureImg}
            link="/project/safety-culture-onboarding"
          />
        </div>
        <div className="col-12 col-md-4">
          <Project
            title="Product Tour Research"
            subTitle="Deputy"
            img={deputyTourImg}
            link="/project/deputy-product-tour"
          />
        </div>
        <div className="col-12 col-md-4">
          <Project
            title="Onboarding experiments"
            subTitle="Deputy"
            img={deputyGrowthExperimentsImg}
            link="/project/deputy-growth-experiments"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4">
          <Project
            title="Gamification redesign"
            subTitle="Deputy"
            img={deputyGamificationImg}
            link="/project/deputy-gamification"
          />
        </div>
        <div className="col-12 col-md-4">
          <Project
            title="Tournament Mobile app"
            subTitle="CSAA Savez"
            link="/project/tournament-app"
            img={csaaImg}
          />
        </div>
        <div className="col-12 col-md-4">
          <Project
            title="Compliance, New York Fairworkweek"
            subTitle="Deputy"
            img={deputyComplianceImg}
            link="/project/deputy-compliance"
          />
        </div>
      </div>
    </Section>

    <VideoSection />
    <AdpListSection />
  </Layout>
)

export default IndexPage
