import * as styles from "./adplist-section.module.css"

import Flickity from "react-flickity-component"
import React from "react"
import adplistImg1 from "../../images/adplist-1.png"
import adplistImg2 from "../../images/adplist-2.png"
import adplistImg3 from "../../images/adplist-3.png"
import adplistImg4 from "../../images/adplist-4.png"
import adplistImg5 from "../../images/adplist-5.png"
import adplistImg6 from "../../images/adplist-6.png"

const AdpListSection = () => {
  return (
    <section id="adplist-section" className={styles.section}>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center mb-4">
            <h2>Mentoring designers at ADPList</h2>
            <p className={styles.description}>
              I'm so grateful to be a part of such an amazing design
              community.If you're looking for a mentor or just keen to chat
              about all things design let's connect!
            </p>
            <a
              className={styles.link}
              href="https://adplist.org/mentors/slava"
              rel="noreferrer"
              target="_blank"
            >
              Book a session
            </a>
          </div>
        </div>
        <div className="row">
          <div className={`col-12`}>
            <Flickity
              className={"carousel"} // default ''
              elementType={"div"} // default 'div'
              options={{
                initialIndex: 1,
              }}
              disableImagesLoaded={false} // default false
              reloadOnUpdate // default false
              static // default false
            >
              <div className={styles.carouselImage}>
                <img
                  className={styles.image}
                  src={adplistImg2}
                  alt="ADPList review"
                />
              </div>
              <div className={styles.carouselImage}>
                <img
                  className={styles.image}
                  src={adplistImg1}
                  alt="ADPList review"
                />
              </div>
              <div className={styles.carouselImage}>
                <img
                  className={styles.image}
                  src={adplistImg3}
                  alt="ADPList review"
                />
              </div>
              <div className={styles.carouselImage}>
                <img
                  className={styles.image}
                  src={adplistImg4}
                  alt="ADPList review"
                />
              </div>
              <div className={styles.carouselImage}>
                <img
                  className={styles.image}
                  src={adplistImg5}
                  alt="ADPList review"
                />
              </div>
              <div className={styles.carouselImage}>
                <img
                  className={styles.image}
                  src={adplistImg6}
                  alt="ADPList review"
                />
              </div>
            </Flickity>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AdpListSection
