import * as styles from "./video-section.module.css"

import React from "react"
import videoImg from "../../images/presentation-cover.png"

const VideoSection = () => (
  <section id="video-section" className={styles.section}>
    <div className="container">
      <div className="row">
        <div className={`col-12 col-md-6 ${styles.imageSection}`}>
          <img
            className={styles.img}
            src={videoImg}
            alt="Turning newly signed up users into returning customers"
          />
        </div>
        <div className={`col-12 col-md-6 ${styles.textSection}`}>
          <h3 className={styles.header}>
            Turning newly signed up users into returning customers
          </h3>
          <p className={styles.description}>
            A company’s onboarding process is the first opportunity to deliver a
            great customer experience.
          </p>
          <a
            className={styles.button}
            rel="noreferrer"
            href="https://youtu.be/-zyVSwOuSec"
            target="_blank"
          >
            Watch presentation
          </a>
        </div>
      </div>
    </div>
  </section>
)

export default VideoSection
