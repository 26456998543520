import * as styles from "./hero.module.css"

import React from "react"
import heroMediumImg from "../../images/slava-hero.png"
import squiggle1 from "../../images/squiggle-1.png"
import squiggle2 from "../../images/squiggle-2.png"

const Hero = () => (
  <section className={styles.hero}>
    <div className="container">
      <div
        className={`row align-items-center justify-content-between ${styles.fullscreen}`}
      >
        <div className="col-12 col-xl-6 banner-left">
          <h1 className={styles.header}>
            Hi, I'm <strong>Slava</strong>
            <br />
            Sydney based
            <br />
            <div className={styles.title}>
              <div className={styles.titlePart}>
                <strong>Growth</strong>
                <img className={styles.squiggle} src={squiggle1} alt="squiggle" />
              </div>
              <div className={styles.titlePart}>
                <strong>Designer</strong>
                <img className={styles.squiggle} src={squiggle2} alt="squiggle" />
              </div>
            </div>
          </h1>

          <h2 className={styles.subHeader}>
            I’m an analytically-minded designer with an accounting background.
          </h2>
        </div>
      </div>
    </div>

    <img
      className={`d-none d-xl-block  ${styles.img}`}
      src={heroMediumImg}
      alt="Slava Kovalska"
    />
  </section>
)

export default Hero
