import * as styles from "./project.module.css"

import { Link } from "gatsby"
import React from "react"
import classNames from "classnames"

const Project = ({ img, title, subTitle, link }) => {
  if (!link) {
    return (
      <div className={styles.project}>
        {img ? (
          <img className={styles.img} src={img} alt={title} />
        ) : (
          <div className={styles.placeholder}>Coming soon</div>
        )}

        <h4 className={styles.title}>{title}</h4>
        <p className={styles.type}>{subTitle}</p>
      </div>
    )
  }

  return (
    <Link to={link}>
      <div className={classNames(styles.project, styles.hoverable)}>
        {img ? (
          <img className={styles.img} src={img} alt={title} />
        ) : (
          <div className={styles.placeholder}></div>
        )}
        <h4 className={styles.title}>{title}</h4>
        <p className={styles.type}>{subTitle}</p>
      </div>
    </Link>
  )
}

export default Project
